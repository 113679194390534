import React from 'react'

const Scrollbar = () => {
    return (
        <>
            <div className="mt-[75px] flex flex-col m-auto p-auto bg-[#2a2a2a] overflow-hidden p-[32px]">
                <div className="flex hide-scroll-bar">
                    <div className="flex flex-nowrap lg:ml-40 md:ml-20 ml-10">
                        <div className="px-3 flex flex-row">
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                            <h1 className='text-white font-bold text-3xl marquee mx-5'>#HACKTHEPLANET</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scrollbar