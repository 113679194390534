import React from 'react'
import Profile from '../components/Profile'
import Navbar from '../components/Navbar'

const ProfilePage = () => {
    return (
        <>
            <Navbar />

            <Profile />
        </>

    )
}

export default ProfilePage