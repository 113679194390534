import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
    const [nav, setNav] = useState(true);

    const handleNav = () => {
        setNav(!nav);
    }

    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.reload();
        window.location = '/login';
    };

    const isLoggedIn = () => {
        // Check if the token exists in localStorage
        return localStorage.getItem('token') !== null;
    }

    return (
        <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
            <h1 className='w-full text-3xl font-bold text-[#00df9a]'>
                {
                    isLoggedIn() ? (
                        <a href='/dashboard'>BountyBible</a>
                    )
                        :
                        (
                            <a href='/'>BountyBible</a>
                        )
                }
            </h1>

            <ul className='hidden md:flex w-full justify-end'>
                <li className='p-4 cursor-pointer hover:text-[#6BEBB7]'><a href='/'>Home</a></li>
                {
                    isLoggedIn() ? (
                        <>
                            <li className='p-4 cursor-pointer hover:text-[#6BEBB7]'><a href='/dashboard'>Dashboard</a></li>
                            <li className='p-4 cursor-pointer hover:text-[#6BEBB7]'><a href='/notebooks'>Notebooks</a></li>
                            <li className='p-4 cursor-pointer hover:text-[#6BEBB7]' style={{whiteSpace: 'nowrap'}}><a href='/notebooks/shared'>Shared Notebooks</a></li>
                        </>
                    ) : ''

                }

                {
                    isLoggedIn() ?
                        (
                            <>

                                <li className='p-4 cursor-pointer hover:text-[#6BEBB7]'><a href='/profile'>Profile</a></li>
                                <li className='p-4 cursor-pointer hover:text-[#6BEBB7]' onClick={handleLogout}><a>Logout</a></li>

                            </>
                        ) :
                        <>
                            <li className='p-4 cursor-pointer hover:text-[#6BEBB7]'><a href='/about'>About</a></li>
                            <li className='p-4 cursor-pointer hover:text-[#6BEBB7]'><a href='/signup'>Signup</a></li>
                            <li className='p-4 cursor-pointer hover:text-[#6BEBB7]'><a href='/login'>Login</a></li>

                        </>
                }
            </ul>

            <div onClick={handleNav} className='block md:hidden'>
                {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
            </div>

            {/* Mobile */}
            <div className={!nav ? 'z-10 fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'fixed left-[-100%]'}>
                <h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>
                    {
                        isLoggedIn() ? (
                            <a href='/dashboard'>BountyBible</a>
                        )
                        :
                        (
                            <a href='/'>BountyBible</a>
                        )
                    }
                </h1>
                <ul className='uppercase p-4'>
                    <li className='p-4 border-b border-gray-600 hover:text-[#6BEBB7]'><a href='/'>Home</a></li>
                    {
                        isLoggedIn() ? (
                            <>
                                <li className='p-4 border-b border-gray-600 hover:text-[#6BEBB7]'><a href='/dashboard'>Dashboard</a></li>
                                <li className='p-4 border-b border-gray-600 hover:text-[#6BEBB7]'><a href='/notebooks'>Notebooks</a></li>
                                <li className='p-4 border-b border-gray-600 hover:text-[#6BEBB7]'><a href='/notebooks/shared'>Shared Notebooks</a></li>
                            </>
                        ) : ''

                    }
                    {
                        isLoggedIn() ?
                            (
                                <>
                                    <li className='p-4 border-b border-gray-600 hover:text-[#6BEBB7]'><a href='/profile'>Profile</a></li>
                                    <li className='p-4 ' onClick={handleLogout}><a>Logout</a></li>

                                </>
                            ) :
                            <>
                                <li className='p-4 border-b border-gray-600 hover:text-[#6BEBB7]'><a href='/about'>About</a></li>
                                <li className='p-4 border-b border-gray-600 hover:text-[#6BEBB7]'><a href='/signup'>Signup</a></li>
                                <li className='p-4 border-gray-600 hover:text-[#6BEBB7]'><a href='/login'>Login</a></li>

                            </>
                    }

                </ul>
            </div>
        </div>
    )
}
export default Navbar;