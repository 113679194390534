import React from 'react'
import Navbar from '../components/Navbar'
import Notebook from '../components/Notebooks/Notebook'


const NotebookPage = () => {
    return (
        <>
            <Navbar />
            <Notebook />
        </>
    )
}

export default NotebookPage