import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Favorites from './Favorites';
import toast, { Toaster } from 'react-hot-toast';

const Profile = () => {
    const [userInformation, setUserInfomation] = useState();
    const [favorites, setFavorites] = useState([]);
    const [error, setError] = useState(null);
    const [subscribed, setSubscribed] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch user ID
                const token = localStorage.getItem('token');
                if (!token) {
                    setError('Token not found in localStorage.');
                    window.location = '/login';
                    return;
                }

                // Authorized?
                const axiosInstance = axios.create({
                    baseURL: 'https://api.bountybible.com',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
                    },
                });

                const profileResponse = await axiosInstance.get('/api/profile');
                setUserInfomation(profileResponse.data.user);

                // Fetch user favorites based on the obtained user ID
                // const favoritesResponse = await axiosInstance.get('/api/favorite/getFavorites');
                // setFavorites(favoritesResponse.data.favorites);


                axiosInstance.post('/api/id', { token })
                    .then(async (response) => {
                        const uid = response.data.user_id;
                        await axiosInstance.post('/api/subscribe/checkSubscription', { user_id: uid })
                            .then((response) => {
                                if (response.data.message === 'subscribed') {
                                    setSubscribed(true);
                                } else {
                                    setSubscribed(false);
                                }
                            })
                    });

            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Error fetching data.');
            }
        };

        // Call the fetchData function
        fetchData();

    }, []); // The empty dependency array means this effect will run once when the component mounts

    const handleCheckboxChange = (e) => {
        const token = localStorage.getItem('token');
        const axiosInstance = axios.create({
            baseURL: 'https://api.bountybible.com',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
            },
        });

        // Your subscribe logic goes here
        try {
            axiosInstance.post('/api/id', { token })
                .then(async (response) => {
                    const uid = response.data.user_id;

                    const subscribe = axiosInstance.post('/api/subscribe', { user_id: uid }).then((response) => {
                        // console.log(`Subscribe: ${response.data.message}`);
                        if (response.data.message === 'Unsubscribed successfully') {
                            setSubscribed(false);
                            toast.success(<b>Unsubscribed!</b>)
                        } else {
                            setSubscribed(true);
                            toast.success(<b>Subscribed!</b>)
                        }
                    }).catch((error) => {
                        console.log(`Error subscribing: ${error}`);
                    })

                    toast.promise(subscribe, {
                        loading: <b>Please wait...</b>,
                        error: <b>Error</b>
                    });
                })
                .catch((error) => {
                    console.log(`Error getting UID: ${error}`);
                })
        }
        catch {

        }

    };


    return (

        <>
            <Toaster />
            <div className='max-w-[1240px] flex flex-col text-white mx-auto'>
                <div className='p-4'>
                    {userInformation ? (
                        <>
                            <h1 className='text-2xl font-bold'>Welcome, {userInformation.firstName}</h1>
                            <p className='font-medium'>{userInformation.email}</p>
                        </>
                    ) : 'Loading...'}
                </div>
                <hr />

                <div className="flex items-center mb-4 mx-5 mt-5">

                    <input
                        onChange={(e) => handleCheckboxChange(e)}
                        checked={subscribed}
                        id="default-checkbox" type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />

                    <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-white">Receive weekly digest of the top tips!</label>

                </div>

                <div className='p-4 text-white'>
                    <h3 className='text-white'>Well, that's all folks! There is nothing to view here as of this moment. This means you're on of the first people to try BountyBible! 🎊 Thank you for taking the time to explore BountyBible.</h3>
                    <br />
                    <div className='flex flex-row justify-end font-bold'>
                    <h3>- The BountyBible Team 🐞</h3>
                    </div>
                </div>

                {/* 
                <div className='p-4 text-white'>
                    <h1 className='text-2xl text-white'>Favorites:</h1>
                    <Favorites/>
                </div> */}
            </div>
        </>
    );
};

export default Profile;
