import React, { useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga';

const CreateNotebook = () => {
  const [notebookName, setNotebookName] = useState('');
  const [notebookDescription, setNotebookDescription] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);


  function reportNotebookCreation() {
    // Track a custom event
    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on Create Notebook',
      label: 'Create Notebook page',
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      reportNotebookCreation();
    }
    catch (error) {
      console.log(`Error reporting GA: ${error}`);
    }


    try {
      // Send a POST request with the input field values
      const token = localStorage.getItem('token');
      const axiosInstance = axios.create({
        baseURL: 'https://api.bountybible.com',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      // Create notebook
      const notebookResponse = await axiosInstance.post('/api/notebooks', {
        name: notebookName,
        description: notebookDescription,
      });

      // Redirect or show a success message
      window.location = "/notebooks";

    } catch (error) {
      // Handle errors, e.g., show an error message
      console.log(error.response.status);
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        console.log(error);
        setError(error);
      } else {
        setError('An error occurred');
      }

      // Display the error using toast
      toast.error(<b>{error ? error.response.message || 'An error occurred' : 'error'}</b>);
    }
  };

  return (
    <>
      <Toaster />
      <section className="md:mt-[-50px] bg-black dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create Notebook
              </h1>
              <div className="space-y-4 md:space-y-6">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="notebookName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                  <input type="text" id="notebookName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Recon Resource" name="notebookName" value={notebookName}
                    onChange={(e) => setNotebookName(e.target.value)} maxLength={120}
                    required />

                  <label htmlFor="notebookDescription" className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                  <input type="text" name="notebookDescription" id="notebookDescription" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Top recon tips from jhaddix" value={notebookDescription}
                    onChange={(e) => setNotebookDescription(e.target.value)}  maxLength={100}
                    required />
                  <button disabled={loading} type="submit" className="mt-5 w-full text-black bg-[#00df9a] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-slate-200">
                    {
                      loading ? 'Creating Notebook...' : 'Create Notebook'
                    }
                  </button>
                </form>
                {/* {error && <div className="mt-4 text-red-800 px-5 py-2 bg-red-200 rounded-md">{error}</div>} */}

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className='w-full max-w-[1240px] h-screen px-5 mt-10 mx-auto'>
        <h1 className='text-white text-2xl font-bold mb-3'>Create Notebook</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3 pt-0">
            <input
              type="text"
              placeholder="Notebook Name"
              value={notebookName}
              onChange={(e) => setNotebookName(e.target.value)}
              className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-[300px]"
            />
          </div>
          <div className="mb-5 pt-0">
            <input
              type="text"
              placeholder="Notebook Description"
              value={notebookDescription}
              onChange={(e) => setNotebookDescription(e.target.value)}
              className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-[300px]"
            />
          </div>

        
          <button
            type="submit"
            className="bg-[#00df9a] w-[200px] rounded-md font-medium mx-auto py-3 px-4 text-black"
          >
            Save Changes
          </button>
        </form>
      </div> */}
    </>
  );
};

export default CreateNotebook;
