import { useState, useRef} from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Navbar from '../Navbar'
import ReactGA from 'react-ga';
import toast, { Toaster } from "react-hot-toast";


import ReCAPTCHA from "react-google-recaptcha";

// import bblogo from '../assets/bb-logo.jpg'

function reportSignup() {
	// Track a custom event
	ReactGA.event({
		category: 'Button Click',
		action: 'Clicked on Sign up',
		label: 'Sign up page',
	});
	// Continue with your button's click handler
}



const Signup = () => {

	const recaptcha = useRef();
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
	});
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};


	// Check Captcha
	const submitForm = async (e) => {
		e.preventDefault();
		setLoading(true);

		const captchaValue = recaptcha.current.getValue();

		if (!captchaValue) {
			setError("Please verify the reCAPTCHA!");
			setLoading(false);
		} else {
			const res = await fetch("https://api.bountybible.com/verify", {
				method: "POST",
				body: JSON.stringify({ captchaValue }),
				headers: {
					"content-type": "application/json",
				},
			});

			const verification = await res.json();
			if (verification.success) {
				await handleSubmit(e);
			} else {
				setError("reCAPTCHA validation failed!");
				setLoading(false);
			}
		}
	}


	// Submit The Form
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		// GA stuff
		try {
			reportSignup();
		}
		catch (error) {
			console.log(`Error reporting GA: ${error}`);
		}

		// Signup user
		try {
			// const url = "http://127.0.0.1:3000/api/users";
			const url = "https://api.bountybible.com/api/users";

			// Send req
			await toast.promise(
				axios.post(url, data).then((res) => {
					navigate("/login?success=true");
					// console.log(res.message);
				}),
				{
					loading: 'Signing Up...',
					success: () => {
						toast.success((
							<div>
								<b>Success</b>
							</div>
						));
						setLoading(false);
					},
					error: (error) => {
						toast.error((
							<div>
								<b>Error: {error.response.data.message}</b>
							</div>
						));
						setLoading(false);
					},
				}
			);
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	}

	return (
		<>
			<Toaster />
			<Navbar />
			<section className="md:mt-[-50px] bg-black dark:bg-gray-900">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
					{/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
						<img className="w-12 h-12 mr-1" src={bblogo} alt="logo" />
						<span className="text-[#00df9a] font-bold">BountyBible</span>
					</a> */}
					<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
						<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
							<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
								Create an account
							</h1>
							<div className="space-y-4 md:space-y-6">
								<div>
									<label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
									<input type="firstName" id="firstName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John" name="firstName"
										onChange={handleChange}
										value={data.firstName}
										required />

									<label htmlFor="lastName" className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
									<input type="lastName" name="lastName" id="lastName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Doe" onChange={handleChange}
										value={data.lastName}
										required />


									<label htmlFor="email" className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
									<input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required onChange={handleChange}
										value={data.email} />
								</div>
								<div>
									<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
									<input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange}
										value={data.password} />
								</div>
								{/* <div className="flex items-start">
									<div className="flex items-center h-5">
										<input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required />
									</div>
									<div className="ml-3 text-sm">
										<label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#" required>Terms and Conditions</a></label>
									</div>
								</div> */}
								{error && <div className="mt-4 text-red-800 px-5 py-2 bg-red-200 rounded-md">{error}</div>}

								<button disabled={loading} type="submit" onClick={submitForm} className="w-full text-black bg-[#00df9a] hover:bg-[#0ae9a2] active  focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-slate-200">{loading ? 'Creating Account...' : 'Create an account'}</button>
								<div className="flex justify-center mt-3">
										<ReCAPTCHA
											ref={recaptcha}
											sitekey={process.env.REACT_APP_SITE_KEY}
										/>
									</div>


								<p className="text-sm font-light text-gray-500 dark:text-gray-400">
									Already have an account? <a href="#" className="font-medium text-primary-600 hover:underline dark:text-primary-500"><Link to='/login'>
										Login here
									</Link></a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
};

export default Signup;