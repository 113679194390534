import React from 'react';

const Loader = ({PageName}) => {
  return (
    <div className='mt-5 justify-center mx-auto'>
      <div className='mx-auto' style={{
        border: '4px solid rgba(255, 255, 255, 0.1)',
        borderRadius: '50%',
        borderTop: '4px solid #fff',
        width: '40px',
        height: '40px',
        animation: 'spin 1s linear infinite'
      }}></div>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      
      <h1 className='mt-5 font-bold text-2xl mx-auto text-white'>Loading {PageName}...</h1>
    </div>
  );
};

export default Loader;
