import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import axios from "axios";
import Navbar from "../Navbar";
import toast, { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga';

import ReCAPTCHA from "react-google-recaptcha";


const Login = () => {
	const recaptcha = useRef();
	const [data, setData] = useState({ email: "", password: "" });
	const [error, setError] = useState("");
	const [loggingIn, setLoggingIn] = useState(false);

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	function reportLogin() {
		// Track a custom event
		ReactGA.event({
			category: 'Button Click',
			action: 'Clicked on Log in',
			label: 'Log in page',
		});
	}


	// Handle Login
	const submitForm = async (e) => {
		e.preventDefault();
		setLoggingIn(true);

		const captchaValue = recaptcha.current.getValue();

		if (!captchaValue) {
			setError("Please verify the reCAPTCHA!");
			setLoggingIn(false);
		} else {
			const res = await fetch("https://api.bountybible.com/verify", {
				method: "POST",
				body: JSON.stringify({ captchaValue }),
				headers: {
					"content-type": "application/json",
				},
			});

			const verification = await res.json();
			if (verification.success) {
				await handleSubmit(e);
			} else {
				setError("reCAPTCHA validation failed!");
				setLoggingIn(false);
			}
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoggingIn(true);

		// GA Tracking
		try {
			reportLogin();
		}
		catch (error) {
			console.log(`Error reporting GA: ${error}`);
		}

		// const url = 'http://127.0.0.1:3000/api/auth';
		const url = 'https://api.bountybible.com/api/auth';


		try {
			toast.promise(
				axios.post(url, data)
					.then((res) => {
						localStorage.setItem("token", res.data.data);
						window.location = "/dashboard";
						return res.data.message; // Return the success message
					}),
				{
					loading: 'Loading...',
					success: (message) => {
						toast.success(<div>
							<b>Success:</b> {message}
						</div>);
						setLoggingIn(false);
					},
					error: (error) => {
						toast.error(
							<div>
								<b>Error:</b> {getErrorDetails(error)}
							</div>

						)
						setLoggingIn(false);
					},
				}
			);


			// Helper function to extract error details
			const getErrorDetails = (error) => {
				if (error.response && error.response.data && error.response.data.message) {
					return error.response.data.message;
				} else if (error.message) {
					return error.message;
				} else {
					return 'An unexpected error occurred';
				}
			};

		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}

	};


	// In your login component
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const success = queryParams.get('success');


	useEffect(() => {
		
		if (success === 'true') {
			const confirmation_required = toast.success(<b>Please confirm your email before you login</b>);

			return () => {
				// Cleanup function, remove any subscriptions or listeners
				toast.dismiss(confirmation_required);
			};
		}
		else if (success === 'confirmed') {
			const email_confirmed = toast.success(<b>Email confirmed! Please login.</b>);

			return () => {
				// Cleanup function, remove any subscriptions or listeners
				toast.dismiss(email_confirmed);
			};
		}
		else if (success === 'error'){
			const general_error = toast.error(<b>Error confirming user!</b>);

			return () => {
				toast.dismiss(general_error);
			}
		}
		else if (success === 'invalid_token'){
			const invalid_token = toast.error(<b>Invalid confirmation token!</b>);

			return () => {
				toast.dismiss(invalid_token);
			}
		}


	}, [success]);
	return (
		<>
			<Toaster />
			<Navbar />
			<section className="md:mt-[-50px] bg-black dark:bg-gray-900">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
					<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
						<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
							<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
								Log in
							</h1>
							<div className="space-y-4 md:space-y-6">
								<form onSubmit={submitForm}>
									<div>

										<label htmlFor="email" className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
										<input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required onChange={handleChange}
											value={data.email} />
									</div>
									<div>
										<label htmlFor="password" className="mt-3 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
										<input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required onChange={handleChange}
											value={data.password} />
									</div>

									{error && <div className="mt-4 text-red-800 px-5 py-2 bg-red-200 rounded-md">{error}</div>}
									<button disabled={loggingIn} type="submit" onClick={submitForm} className="mt-5 w-full text-black bg-[#00df9a] hover:bg-[#0ae9a2] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-slate-200">{loggingIn ? 'Logging in...' : 'Log in'}</button>

									<div className="flex justify-center mt-3">
										<ReCAPTCHA
											ref={recaptcha}
											sitekey={process.env.REACT_APP_SITE_KEY}
										/>
									</div>



									<p className="mt-3 text-sm font-light text-gray-500 dark:text-gray-400">
										Don't have an account? <a href="#" className="font-medium text-primary-600 hover:underline dark:text-primary-500"><Link to='/signup'>
											Create account here
										</Link></a>
									</p>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>

	);
};

export default Login;
