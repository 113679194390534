import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Tips from '../components/Tips/Tips';
import Loader from '../components/Loader';
import FetchTips from '../components/Tips/FetchTips';
import LazyTipsWrapper from '../components/Tips/LazyTipsWrapper';
import fetchNotebooks from '../components/Notebooks/FetchNotebooks'; // Adjust the import path
import SearchBar from '../components/SearchBar';
import axios from 'axios';
import toast,{Toaster} from 'react-hot-toast';

const Dashboard = (userDetails) => {


  // Using State
  const [loading, setLoading] = useState(true);
  const [fetchedTipsData, setFetchedTipsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  // const [favoiteTipIds, setFavoriteTipIds] = useState();


  const notebooks = fetchNotebooks();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosInstance = axios.create({
          baseURL: 'https://api.bountybible.com',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const response = await axiosInstance.get('/api/tips');
        setFetchedTipsData(response.data);
        toast.success(<b>Tips fetched!</b>);

      } catch (error) {
        console.error(`Error fetching data: ${error}`);
        toast.error('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    if (fetchedTipsData.length === 0) {
      fetchData();
    }
  }, [fetchedTipsData]); // Empty dependency array ensures useEffect runs only once on component mount



  const handleDataFetched = (data) => {
    setFetchedTipsData(data);
  };

  // Function to filter tips based on search query
  const filteredTips = fetchedTipsData.filter((tip) =>
    tip.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle when the filter is clicked
  const handleFilterClick = (filter) => {
    setSearchQuery(filter);
    setSelectedFilter(filter);
  };


  // const checkFavorite = () => {

  //   const token = localStorage.getItem('token');
  //   const axiosInstance = axios.create({
  //     baseURL: 'https://api.bountybible.com',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
  //     },
  //   });

  //   axiosInstance.get('/api/favorite/getFavorites', { token })
  //     .then(response => {
  //       // Get current user's favorites
  //       console.log('Set favorites.');
  //       const favoritesData = response.data.favorites;

  //       // Extract tip IDs from favorites
  //       const tipIds = favoritesData.map((favorite) => favorite.tip_id);
  //       setFavoriteTipIds(tipIds);
  //     }).catch(error => {
  //       console.log(`Error finding favorited tips: ${error}`);
  //     })
  // }


  const TipsComponent = loading ? LazyTipsWrapper : Tips;



  return (
    <>
    <Toaster/>
      <Navbar />
      <div className='w-full pb-[5rem] pt-[2.5rem] px-4'>
        <FetchTips onDataFetched={handleDataFetched} />

        {/* Search bar */}

        <div className='sm:w-[300px] md:w-[700px] mx-auto'>
          <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
          <div className="relative flex items-center">
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-[#00df9a] focus:border-[#00df9a] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#00df9a] dark:focus:border-[#00df9a]"
              placeholder="Search tips..."
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="absolute inset-y-0 end-0 flex items-center pr-3 pointer-events-none">
              <div className="w-8 h-8 bg-[#00df9a] rounded-full flex items-center justify-center mr-2">
                <svg
                  className="w-4 h-4 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>


        {/* Filters - Will add to array and use map later */}
        <div className='flex flex-row max-w-[1240px] mx-auto justify-between my-5 overflow-scr'>
          <div className={`filter-item flex flex-col cursor-pointer hover:text-white ${selectedFilter === '' ? 'text-white' : 'text-gray-500'}`} onClick={() => handleFilterClick("")}>
            {/* Icon */}
            <svg className={`w-4 h-4 mx-auto  hover:text-white${selectedFilter === '' ? 'text-white' : 'text-gray-500'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 19 20">
              <path d="M16.025 15H14.91c.058-.33.088-.665.09-1v-1h2a1 1 0 0 0 0-2h-2.09a5.97 5.97 0 0 0-.26-1h.375a2 2 0 0 0 2-2V6a1 1 0 0 0-2 0v2H13.46a6.239 6.239 0 0 0-.46-.46V6a3.963 3.963 0 0 0-.986-2.6l.693-.693A1 1 0 0 0 13 2V1a1 1 0 0 0-2 0v.586l-.661.661a3.753 3.753 0 0 0-2.678 0L7 1.586V1a1 1 0 0 0-2 0v1a1 1 0 0 0 .293.707l.693.693A3.963 3.963 0 0 0 5 6v1.54a6.239 6.239 0 0 0-.46.46H3V6a1 1 0 0 0-2 0v2a2 2 0 0 0 2 2h.35a5.97 5.97 0 0 0-.26 1H1a1 1 0 0 0 0 2h2v1a6 6 0 0 0 .09 1H2a2 2 0 0 0-2 2v2a1 1 0 1 0 2 0v-2h1.812A6.012 6.012 0 0 0 8 19.907V10a1 1 0 0 1 2 0v9.907A6.011 6.011 0 0 0 14.188 17h1.837v2a1 1 0 0 0 2 0v-2a2 2 0 0 0-2-2ZM11 6.35a5.922 5.922 0 0 0-.941-.251l-.111-.017a5.52 5.52 0 0 0-1.9 0l-.111.017A5.924 5.924 0 0 0 7 6.35V6a2 2 0 1 1 4 0v.35Z"></path>
            </svg>
            {/* Text */}
            <p className='mt-2 font-medium'>All</p>
          </div>
          <div className={`filter-item flex flex-col cursor-pointer hover:text-white ${selectedFilter === 'SQL' ? 'text-white' : 'text-gray-500'}`} onClick={() => handleFilterClick("SQL")}>
            {/* Icon */}
            <svg className={`w-4 h-4 mx-auto  hover:text-white${selectedFilter === 'SQL' ? 'text-white' : 'text-gray-500'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="14" fill="none" viewBox="0 0 20 14">
              <path stroke="currentColor" strokeWidth="2" d="M1 5h18M1 9h18m-9-4v8m-8 0h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1Z" />
            </svg>
            {/* Text */}
            <p className='mt-2 font-medium'>SQLi</p>
          </div>
          <div className={`filter-item flex flex-col cursor-pointer hover:text-white ${selectedFilter === 'SSRF' ? 'text-white' : 'text-gray-500'}`} onClick={() => handleFilterClick("SSRF")}>
            {/* Icon */}
            <svg className={`w-4 h-4 mx-auto  hover:text-white${selectedFilter === 'SSRF' ? 'text-white' : 'text-gray-500'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
              <path d="M17 0h-5.768a1 1 0 1 0 0 2h3.354L8.4 8.182A1.003 1.003 0 1 0 9.818 9.6L16 3.414v3.354a1 1 0 0 0 2 0V1a1 1 0 0 0-1-1Z"></path>
              <path d="m14.258 7.985-3.025 3.025A3 3 0 1 1 6.99 6.768l3.026-3.026A3.01 3.01 0 0 1 8.411 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V9.589a3.011 3.011 0 0 1-1.742-1.604Z"></path>
            </svg>
            {/* Text */}
            <p className='mt-2 font-medium'>SSRF</p>
          </div>
          <div className={`filter-item flex flex-col cursor-pointer hover:text-white ${selectedFilter === 'IDOR' ? 'text-white' : 'text-gray-500'}`} onClick={() => handleFilterClick("IDOR")}>
            {/* Icon */}
            <svg className={`w-4 h-4 mx-auto  hover:text-white${selectedFilter === 'IDOR' ? 'text-white' : 'text-gray-500'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
              <path d="M16 0H4a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v2H1a1 1 0 0 0 0 2h1v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4.5a3 3 0 1 1 0 6 3 3 0 0 1 0-6ZM13.929 17H7.071a.5.5 0 0 1-.5-.5 3.935 3.935 0 1 1 7.858 0 .5.5 0 0 1-.5.5Z" />
            </svg>

            {/* Text */}
            <p className='mt-2 font-medium'>IDOR</p>
          </div>
          <div className={`filter-item flex flex-col cursor-pointer hover:text-white ${selectedFilter === 'Recon' ? 'text-white' : 'text-gray-500'}`} onClick={() => handleFilterClick("Recon")}>
            {/* Icon */}
            <svg
              className={`w-4 h-4 mx-auto  hover:text-white${selectedFilter === 'Recon' ? 'text-white' : 'text-gray-500'}`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
            {/* Text */}
            <p className='mt-2 font-medium'>Recon</p>
          </div>
        </div>


        {/* Tip Cards */}
        {/* <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 lg:grid-cols-3'> */}
        <div className='max-w-[1240px] mx-auto flex flex-row flex-wrap'>
          {
            loading ? (
              <div className='mx-auto flex justify-center'>
                <Loader />
              </div>
            ) : (


              filteredTips.map((tip) => (
                <Tips
                  key={tip.id}
                  tip_id={tip.id}
                  isRendered={true}
                  //Check if it's favorited by the current user
                  // isFavorited={checkFavorite(tip.id)}
                  image={tip.images[0] ? tip.images[0] : tip.user.avatar}
                  title={tip.text}
                  description={tip.user.description}
                  url={tip.url}
                  username={tip.user.username}
                  profile_url={tip.user.url}
                  avatar={tip.user.avatar}
                  userFullName={tip.user.userFullName}
                  notebooks={[notebooks, tip._id]}
                  loading={loading}
                />
              ))
            )
          }
        </div>
      </div>
    </>
  );
};

export default Dashboard;
