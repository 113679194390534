import React from 'react'

const Footer = () => {
    return (
        <>
            <section className='w-full bg-[#2a2a2a] py-[32px] text-white mt-12'>
                <div className='flex justify-between px-3 md:px-[56px] '>
                    <div>
                        <p>© {new Date().getFullYear()} BountyBible. All rights reserved.</p>
                    </div>
                    <div>
                        <a target='_blank' className='cursor-pointer hover:text-[#6BEBB7]' href='https://discord.gg/85BhSeEnnP'>Report A Bug</a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer