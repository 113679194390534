import React from 'react'
import Navbar from '../components/Navbar'
import CreateNotebook from '../components/Notebooks/CreateNotebook'


const CreateNotebookPage = () => {
    return (
        <>
            <Navbar />
            <CreateNotebook />
        </>
    )
}

export default CreateNotebookPage