import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Dropdown from './Dropdown'


const Tips = ({ tip_id, isRendered, isFavorited, image, title, description, url, username, profile_url, avatar, userFullName, notebooks, loading }) => {

  const location = useLocation();
  const { pathname } = location;



  const [isFavorite, setIsFavorite] = useState(isFavorited);



  // Do you wanna show it or not?
  if (!isRendered) {
    return null; // Do not render if isRendered is false
  }


  // Check if its being favorited or unfavorited
  const handleFavoriteClick = () => {
    // setIsFavorite(!isFavorite);

    // if (!isFavorite) {
    //   favoriteTip(tip_id);
    // } else {
    //   unfavoriteTip(tip_id);
    // }
  };

  // Favorite the current tip
  const favoriteTip = (tip_id) => {
    // TODO
    // Add the card id and the userid to the favorites table

    console.log(`Const Favorite Tip: ${tip_id}`)

    const token = localStorage.getItem('token');
    // Authorized?
    const axiosInstance = axios.create({
      baseURL: 'https://api.bountybible.com',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
    });

    axiosInstance.post('/api/favorite', { tip_id })
      .then(response => {
        console.log(`Successfully set tip: ${tip_id} as favorite`)
        console.log(response);
      }).catch(error => {
        console.log(error);
        console.log(`An error occured when trying to favorite tip: ${tip_id}`);
      })
  }

  // Unfavorite the current tip 
  const unfavoriteTip = (tip_id) => {
    // TODO: Implement logic to remove the tip from favorites
    console.log(`Unfavoriting tip: ${tip_id}`);
    const token = localStorage.getItem('token');
    // Authorized?
    const axiosInstance = axios.create({
      baseURL: 'https://api.bountybible.com',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
    });

    axiosInstance.post('/api/favorite', { tip_id })
      .then(response => {
        console.log(`Successfully unfavorited tip: ${tip_id}`)
        console.log(response);
      }).catch(error => {
        console.log(error);
        console.log(`An error occured when trying to unfavorite tip: ${tip_id}`);
      })
  };



  return (

    <div className='
        relative max-w-sm sm:max-w-3/4 md:max-w-1/2 lg:max-w-1/3 my-5 mx-auto max-h-[700px]
        bg-[#2a2a2a] border border-white border-opacity-30 rounded-lg shadow'>

      <img className="rounded-t-lg object-cover w-full cover-repeat h-[300px]" src={image ? image : avatar } alt={image ? image : avatar } />
      <div className="p-5 relative">
        {/* Heart Icon */}
        {/* <FaHeart
          onClick={handleFavoriteClick}
          className={`absolute top-2 right-2 cursor-pointer text-red-500 ${isFavorited ? 'opacity-100' : 'opacity-30'}`}
          size={20}
        /> */}

        <h5 className="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white line-clamp-3">{title}</h5>
        <hr />
        <div className='flex flex-row justify-start py-3 place-items-center'>
          <img id="avatar" className='max-w-[40px] max-h-[40px] shadow-lg rounded-full mr-4' src={avatar ? avatar : 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'} alt="" />
          <p className="mb-3 font-normal line-clamp-3 text-white">
            <span className='font-bold'>{userFullName}</span>
            <br/>
            <span className='opacity-75'>{description? description : 'This user profile had no description at time of this post.'}</span>
          </p>
        </div>
        <div className='flex flex-row justify-between pt-5 '>
          <a
            target='_blank'
            href={url}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black  bg-[#00df9a] rounded-lg hover:bg-[#37a884] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-[#00df9a] dark:hover:bg-[#00df9a] dark:focus:ring-[#46d1a5]"
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
            </svg>
          </a>

          {/* <a className='text-gray-500 font-medium' href={profile_url}>
            {username}
          </a> */}


          {
            pathname == '/notebooks' ? '' :

              loading ? '' :
                notebooks[0] ?
                  (
                    <>
                      <Dropdown
                        menuItems={notebooks[0].map((notebook) => ({
                          id: notebook._id,
                          label: notebook.name,
                        }))}

                        tipId={notebooks[1] ? notebooks[1] : null} />
                    </>
                  )
                  : pathname == '' ? 'No notebooks!' : ''
          }
        </div>
      </div>
    </div>
  )
}

Tips.defaultProps = {
  notebooks: [], // Set a default value as an empty array or whatever makes sense as a default for your use case.
};


export default Tips