import React from 'react'
import Navbar from '../components/Navbar'

const About = () => {
    return (
        <>
        <Navbar/>
        <div className="text-white">
            <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
                <p className="text-[#00df9a] text-4xl md:text-6xl font-bold p-2">BountyBible</p><span className='text-gray-500'>v1.0</span>
                <h1 className="pt-3 font-bold text-2xl md:text-3pl px-2">Your goto feed for bugbounty tips</h1>
                <p className="md:text-2xl text-xl font-bold text-gray-500 px-3 pt-4">Monitor the latest bug bounty tips and tricks from the best hackers.</p>
                <a href='/' className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black cursor-pointer">Get started</a>
            </div>
        </div>
        </>
        
    )
}

export default About