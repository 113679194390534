import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import NotebookContent from './NotebookContent';
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../Loader';


const Notebook = () => {
  const [notebooks, setNotebooks] = useState([]);
  const [selectedNotebook, setSelectedNotebook] = useState(null);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');




  const handleSave = async (notebook_id) => {
    // Debug
    console.log(`Saving email: ${email}`);

    const token = localStorage.getItem('token');
    const axiosInstance = axios.create({
      baseURL: 'https://api.bountybible.com',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });


    axiosInstance.post(`/api/notebooks/${notebook_id}/share`, { email: email })
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.status === 400) {
          // Handle 400 Bad Request (Notebook is already shared with the user)
          console.log('Notebook is already shared with this user.');
        } else {
          // Handle other errors
          console.log('An error occurred while sharing the notebook.');
        }
      });

    // console.log(`Sending request to notebook id: ${notebook_id} and to email ${email}`);

    // toggleModal();
  };

  const openNotebook = (notebookId) => {
    setSelectedNotebook(notebookId);
  };

  const shareNotebook = async (notebookId) => {
    console.log('Share notebook....');
    const token = localStorage.getItem('token');
    const axiosInstance = axios.create({
      baseURL: 'https://api.bountybible.com',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log('/api/notebooks/share/' + notebookId);
    await axiosInstance.get(`/api/notebooks/share/${notebookId}`).then((response) => {
      console.log(response);
    });
  };

  // Delete a notbook
  const deleteNotebook = async (notebookId) => {
    // Authorized?
    const token = localStorage.getItem('token');
    const axiosInstance = axios.create({
      baseURL: 'https://api.bountybible.com',
      // baseURL: 'https://api.bountybible.com',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
    });

    // Send a delete request
    // const response = await axiosInstance.delete(`/api/notebooks/${notebookId}`);
    toast.promise(
      axiosInstance.delete(`/api/notebooks/${notebookId}`)
        .then((response) => {
          console.log(response);
          setNotebooks((prevNotebooks) => prevNotebooks.filter((notebook) => notebook._id !== notebookId));
        })
        .catch((error) => {
          setError(error);
        }),
      {
        loading: 'Deleting notebook...',
        success: <b>Success!</b>,
        error: <b>An error occured</b>,
      }
    )
  }

  const goBack = () => {
    setSelectedNotebook(null);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('Token not found in localStorage.');
          window.location = '/login';
          return;
        }

        const axiosInstance = axios.create({
          baseURL: 'https://api.bountybible.com',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const response = await axiosInstance.get('/api/notebooks');
        setNotebooks(response.data);
        toast.success(<b>Loaded Notebooks!</b>);
      } catch (error) {
        setError(error);
        toast.error(error);
        console.log(`Error: ${error}`);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Toaster />

      <div className='w-full'>
        <div className='max-w-[1240px] mx-auto px-5 text-white font-medium'>
          {selectedNotebook ? (
            <div>
              <div className='flex items-center mb-4 cursor-pointer' onClick={goBack}>
                <svg className='w-6 h-6 mr-2' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 19l-7-7 7-7' />
                </svg>
                <span>Go Back</span>
              </div>
              <NotebookContent notebookId={selectedNotebook} />
            </div>
          ) : (
            <div className='mt-5'>
              <div className='flex flex-row justify-between'>
                <h1 className='text-2xl'>Your Notebook List</h1>
                <a href='/notebooks/create' className="bg-[#00df9a] px-5 rounded-md font-medium  py-3 text-black">
                  Create Notebook
                </a>
              </div>

              {notebooks ? (
                notebooks[0] !== null ? (
                  notebooks.map((notebook, index) => (
                    <div className='flex flex-col p-5 rounded-lg bg-slate-800 my-5 text-white' key={`${notebook._id}-${index}`}>
                      <div className='flex flex-row justify-between'>
                        <h1 className='text-xl font-bold cursor-pointer overflow-auto' onClick={() => openNotebook(notebook._id)}>{notebook.name}</h1>

                        <div className='flex justify-end'>

                          {/* Trash Button */}
                          <span className='cursor-pointer text-2xl font-bold text-red-500' onClick={() => { deleteNotebook(notebook._id) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" stroke="#ff0000" fill="#ff0000" x="0px" y="0px" width="25" height="25" viewBox="0 0 30 30">
                              <path d="M6 8v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8H6zM24 4h-6c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1H6C5.4 4 5 4.4 5 5s.4 1 1 1h18c.6 0 1-.4 1-1S24.6 4 24 4z"></path>
                            </svg>
                          </span>

                          {/* Toggle Modal Button */}

                          <Link 
                          to={{
                            pathname: `/notebooks/share-notebook/${notebook._id}`,
                          }}
                          className='cursor-pointer'>
                            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Communication / Share_iOS_Export"> <path id="Vector" d="M9 6L12 3M12 3L15 6M12 3V13M7.00023 10C6.06835 10 5.60241 10 5.23486 10.1522C4.74481 10.3552 4.35523 10.7448 4.15224 11.2349C4 11.6024 4 12.0681 4 13V17.8C4 18.9201 4 19.4798 4.21799 19.9076C4.40973 20.2839 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07899 21 7.19691 21H16.8036C17.9215 21 18.4805 21 18.9079 20.7822C19.2842 20.5905 19.5905 20.2839 19.7822 19.9076C20 19.4802 20 18.921 20 17.8031V13C20 12.0681 19.9999 11.6024 19.8477 11.2349C19.6447 10.7448 19.2554 10.3552 18.7654 10.1522C18.3978 10 17.9319 10 17 10" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g></svg>
                          </Link>

                        </div>
                      </div>
                      <br />
                      <p className='overflow-auto'>Description: {notebook.description}</p>
                    </div>

                  ))
                ) : (
                  'No notebooks found'
                )
              ) : (
                <div className='mt-5 mx-auto flex justify-center'>
                  <Loader PageName='notebooks' />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notebook;