import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tips from '../Tips/Tips';


const NotebookContent = ({ notebookId }) => {
  const [notebook, setNotebook] = useState(null);
  const [tips, setTips] = useState();

  useEffect(() => {
    const fetchNotebookById = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          // Handle the case where the token is not found
          console.log('No token found!');
          return;
        }

        const axiosInstance = axios.create({
          baseURL: 'https://api.bountybible.com',
          // baseURL: 'https://api.bountybible.com',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        await axiosInstance.get(`/api/notebooks/${notebookId}`)
          .then(async response => {
            setNotebook(response.data);

            await axiosInstance.get(`/api/notebooks/${notebookId}/tips`).then(response => {
              setTips(response.data)

            });


          }).catch(error => {
            console.log(`Error getting notebook ids: ${error}`);
          });



      } catch (error) {
        console.error('Error fetching notebook by ID:', error);
      }
    };


    fetchNotebookById();
  }, [notebookId]);

  return (
    <>
      <div>
        {notebook ? (
          <div>
            <h1 className='text-2xl font-bold text-white overflow-auto'>{notebook.name}</h1>
            <p className='overflow-auto'>{notebook.description}</p>
            {/* Add other content properties as needed */}
          </div>
        ) : 'Loading...'}
      </div>

      <div className='max-w-[1240px] mx-auto flex flex-row flex-wrap'>
        {tips ?
          tips.length > 0 ? (
            tips.map((tip) => (
              <div className='mx-auto justify-center' key={tip.id}>
                {/* Show the Tips Card */}
                <Tips
                  key={tip.id}
                  tip_id={tip.id}
                  isRendered={true}
                  isFavorited={true}
                  image={tip.images[0] ? tip.images[0] : tip.user.avatar}
                  title={tip.text}
                  description={tip.user.description}
                  url={tip.url}
                  username={tip.user.username}
                  profile_url={tip.user.url}
                  avatar={tip.user.avatar}
                  userFullName={tip.user.userFullName}
                />
              </div>
            ))
          ) : (
            <>
              <div className='flex flex-col mt-5'>
                <hr />

                <p className='font-medium text-white'>
                  You've got no tips added...
                  <br></br>
                  Go to your <a href="/dashboard" className='font-bold text-green-500'>dashboard</a> and add the tips to your notebook
                </p>
              </div>
            </>
          )
          : 'Loading...'}
      </div>
    </>
  );
};

export default NotebookContent;
