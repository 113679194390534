// Create a new component or hook for notebook fetching
// FetchNotebooks.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchNotebooks = () => {
  const [notebooks, setNotebooks] = useState(null);

  useEffect(() => {
    const fetchNotebooks = async () => {
      try {
        // Fetch notebooks
        const token = localStorage.getItem('token');
        const axiosInstance = axios.create({
          baseURL: 'https://api.bountybible.com',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const response = await axiosInstance.get('/api/notebooks');
        setNotebooks(response.data);
      } catch (error) {
        console.log(`Error fetching notebooks: ${error}`);
      }
    };

    fetchNotebooks();
  }, []);

  return notebooks;
};

export default useFetchNotebooks;
