import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';
import Navbar from '../Navbar';
import NotebookContent from './NotebookContent';
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../Loader'


const SharedNotebooks = () => {

  const [notebooks, setNotebooks] = useState([]);
  const [error, setError] = useState('');
  const [selectedNotebook, setSelectedNotebook] = useState(null);



  const openNotebook = (notebook) => {
    console.log("Selected notebook id: " + notebook);
    setSelectedNotebook(notebook._id);
  }


  	// In your login component
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
  const status = queryParams.get('status');
  


  // Auto run when page is loaded
  useEffect(() => {

    if(status){
      if (status === 'confirmed') {
        const confirmed = toast.success(<b>Notebook has been added to your shared notebooks.</b>);
  
        return () => {
          // Cleanup function, remove any subscriptions or listeners
          toast.dismiss(confirmed);
        };
      }
      else if (status === 'exists'){
        const exists = toast.error(<b>Notebook already exists!</b>);
  
        return () => {
          // Cleanup function, remove any subscriptions or listeners
          toast.dismiss(exists);
        };
      }
      else if (status === 'error') {
        const share_error = toast.success(<b>Notebook couldn't be shared.</b>);
  
        return () => {
          // Cleanup function, remove any subscriptions or listeners
          toast.dismiss(share_error);
        };
      } 
      else if(status === 'invalid_token'){
        const invalid_token = toast.success(<b>Error verifying token.</b>);
  
        return () => {
          // Cleanup function, remove any subscriptions or listeners
          toast.dismiss(invalid_token);
        };
  
      }
    }


    const token = localStorage.getItem('token');
    const axiosInstance = axios.create({
      baseURL: 'https://api.bountybible.com',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
    });

    axiosInstance.get('/api/shared').then((response) => {
      
      if(response.data[0] !== null){
        setNotebooks(response.data);
      } else {
        setNotebooks([]);
      }

    });
  }, [status]);

  const goBack = () => {
    setSelectedNotebook(null);
  };

  return (
    <>
      <Toaster />
      <Navbar />

      <div className='w-full'>
        <div className='max-w-[1240px] mx-auto px-5 text-white font-medium'>

          {selectedNotebook ? (
            <div>
              <div className='flex items-center mb-4 cursor-pointer' onClick={goBack}>
                <svg className='w-6 h-6 mr-2' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 19l-7-7 7-7' />
                </svg>
                <span>Go Back</span>
              </div>
              <NotebookContent notebookId={selectedNotebook} />
            </div>
          ) : (
            <div className='mt-5'>
              <div className='flex flex-row justify-between'>
                <h1 className='text-2xl'>Notebooks Shared with You</h1>
              </div>
              {notebooks ? (
                notebooks.length > 0 ?
                  notebooks.map((notebook) => (
                      <div className='flex flex-col p-5 rounded-lg bg-slate-800 my-5 text-white' key={notebook._id}>
                        <div className='flex flex-row justify-between'>
                          <h1 className='text-xl font-bold cursor-pointer' onClick={() => openNotebook(notebook)}>{notebook.name}</h1>
                        </div>
                        <br />
                        <p>Description: {notebook.description}</p>
                      </div>
                  )) :
                  'No notebooks found'
              ) :
                <div className='mt-5 mx-auto flex justify-center'>
                  <Loader PageName='notebooks' />
                </div>
              }
            </div>
          )}
        </div>
      </div>

      <div className='bg-red-200 text-red-700 font-medium px-3 rounded-lg'>
        {error ? error : ''}
      </div>
    </>
  )
}

export default SharedNotebooks