import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga';

// State
// const [error, setError] = useState('');


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function reportAddToNotebook() {
  // Track a custom event
  ReactGA.event({
    category: 'Button Click',
    action: 'Add tip to notebook',
    label: 'Tips page',
  });
}


function addTipToNotebook(notebook_id, tip_id) {
  try {
    reportAddToNotebook();
  }
  catch (error) {
    console.log(`Error reporting GA: ${error}`);
  }


  try {
    // Fetch user ID
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Token not found in localStorage.');
      window.location = '/login';
      return;
    }

    // Authorized?
    const axiosInstance = axios.create({
      baseURL: 'https://api.bountybible.com',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
    });


    // router.post('/:id/tips/:tipId', requireAuth, notebookController.addTipToNotebook);
    toast.promise(
      axiosInstance.post(`/api/notebooks/${notebook_id}/tips/${tip_id}`)
        .then(response => {
          toast.success(<b>Added tip to notebook!</b>)
          // console.log({ 'message': `Added tip ${tip_id} to ${notebook_id}` });
          // console.log(`Response: ${response.data}`);
        })
        .catch(error => {

          // Log the error for debugging
          console.log(`Error: ${error}`);

          // Extract error message from the response if available
          const errorMessage = error.response?.data?.message || 'Error in adding notebook';

          // Set the error message in the toast
          toast.error(<b>{errorMessage}</b>);
        }),
    );


  } catch (error) {
    console.log(`Error fetching notebook names`);
  }
}

export default function Dropdown(menuItems, tipId) {

  // console.log(menuItems.menuItems);
  // console.log(menuItems.tipId);

  return (
    <>
      <Toaster />
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Add to notebook
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {
                menuItems.menuItems.length > 0 ?
                  menuItems.menuItems.map((menu_item) => (
                    <Menu.Item key={menu_item.id}>
                      {({ active }) => (
                        <a
                          onClick={() => { addTipToNotebook(menu_item.id, menuItems.tipId) }}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          {menu_item.label}
                        </a>
                      )}
                    </Menu.Item>
                  ))
                  :
                  <p className='px-4 font-medium'><b>No <a href='/notebooks' className='text-[#00df9a] underline'>notebooks</a> created 🥲!</b></p>
              }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
