import React from 'react'
import Navbar from '../Navbar'
import Hero from './Hero'
import TripleCards from './TripleCards'
import Scrollbar from './Scrollbar'
import PlatformInAction from './PlatformInAction'
import Footer from './Footer'

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />

      <a href="#section2"><div className="scroll-down"></div></a>

      {/* <PlatformInAction /> */}
      <Scrollbar />
      <TripleCards/>

      <Footer/>
    </>
  )
}

export default Home