import React from 'react'
import Tips from '../Tips/Tips'
import active_card from '../../assets/active-card.png'


const TripleCards = () => {
    return (
        <>
            <section className='mt-[75px] md:mt-[10px] max-w-[1240px] text-white mx-auto px-3' >
                <div className='flex flex-col'>
                    <div className='md:mt-[75px]'>
                        <h1 className='text-white font-bold text-4xl md:text-4xl mb-5 md:mb-[50px] mx-auto text-center'>Bugbounty Tips by Your Favorite Hackers</h1>
                    </div>
                    {/* Tips */}
                    <div className='flex flex-col md:flex-row ease-in-out transition-opacity'>
                        <Tips
                            key='1'
                            tip_id='1'
                            isRendered={true}
                            isFavorited={true}
                            image={active_card}
                            title="⭐ Recon Tip

          Reverse DNS (rDNS) leverages PTR and DNS queries to find domains among your target's IPs. 
          
          @hakluke
           has an excellent tool for this called  Hakrevdns!
          
          https://github.com/hakluke/hakrevdns
          
          Workflow?
          
          1) Get ASN
          2) prips {ASN IP RANGE} | hakrevdns"
                            description="CISO & Hacker in Charge @buddobot
          . Consultant/Speaker/Trainer @arcanuminfosec
          . 18 years hacking + sec leadership. exUbisoft-Bugcrowd-Fortify-HP-Redspin-Citrix."
                            url="https://twitter.com/Jhaddix/status/1734340415782793525"
                            username="Jhaddix"
                            profile_url="https://twitter.com/Jhaddix"
                            avatar="https://pbs.twimg.com/profile_images/1589272686886195201/Wwpi67Vb_400x400.jpg"
                            userFullName="Jason Haddix"
                            notebooks={[]}
                        />


                        <Tips
                            key='2'
                            tip_id='2'
                            isRendered={true}
                            isFavorited={true}
                            image="https://pbs.twimg.com/media/EW7M5uKWoAAXXZ3?format=jpg&name=large"
                            title="Took more than 2 years, but just released the postMessage-tracker Chrome Extension!
              https://github.com/fransr/postMessage-tracker
              
              Look at the functions receiving postMessages directly in the extension, look at the messages and sender/receiver window locations and track everything using a log-URL."
                            description="Dev/Security/Founder at @centrahq
              /@detectify
              /@poweredbyingrid
              . I do not advertise doing hacking services, do not trust the ones telling you I do."
                            url="https://twitter.com/fransrosen/status/1256167158217932800"
                            username="fransrosen"
                            profile_url="https://twitter.com/fransrosen"
                            avatar="https://pbs.twimg.com/profile_images/458927693870792704/vCex42bL_400x400.jpeg"
                            userFullName="Frans Rosén"
                            notebooks={[]}
                        />


                        <Tips
                            key='3'
                            tip_id='3'
                            isRendered={true}
                            isFavorited={true}
                            image="https://pbs.twimg.com/media/GBPlYXdaUAEcmnL?format=png&name=900x900"
                            title="I ❤️ SSRF!  I recently came across this CTF during @snyksec
              's fetch the flag that required me to exploit parse-url in order to get the flag. But I also found an unintended solution! 😏
              
              Check it out here 👉🏼https://youtu.be/_avYi3_Lm9A"
                            description="Cofounder @hackinghub_io
              ! I hack into companies and make content about it. Bug Bounty Village and #NahamCon organizer. ex @hacker0x01
              /@hadriansecurity
               🇮🇷"
                            url="https://twitter.com/NahamSec/status/1734982631878709255"
                            username="NahamSec"
                            profile_url="https://twitter.com/NahamSec"
                            avatar="https://pbs.twimg.com/profile_images/1259918602758545408/hGABtwhL_400x400.jpg"
                            userFullName="Ben Sadeghipour"
                            notebooks={[]}
                        />
                    </div>
                </div>

                {/* <div className='mt-5 flex flex-col'>
                    <h1 className='text-2xl md:text-4xl font-bold mx-auto text-center'>
                        What are you waiting for?
                    </h1>
                    <a href='/signup' className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black text-center animate-pulse ease-in-out">Get started</a>
                </div> */}

            </section>

        </>
    )
}

export default TripleCards