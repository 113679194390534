import React, { useState, useEffect } from 'react';
import { useParams  } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const ShareNotebook = () => {

    const { id } = useParams();
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [sharing, setSharing] = useState(false);

    const handleChange = (email) => {
        setEmail(email);
        // console.log(`Setting email: ${email}`)
    }
    const handleSubmit = () => {
        setSharing(true);
        const token = localStorage.getItem('token');
        const axiosInstance = axios.create({
            baseURL: 'https://api.bountybible.com',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (email === '') {
            setError('Please enter an email');
            return;
        }

        try {
            axiosInstance.post(`/api/notebooks/share`, { notebook_id: id, user_email: email })
                .then((response) => {
                    toast.success(response.data.message);
                    setEmail('');
                    setTimeout(() => {
                        window.location.href = '/notebooks';
                    }, 2500)
                })
                .catch((error) => {
                    console.log("Request Error", error);

                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // other than 2xx. Check the error.response.status for specific status codes.
                        if (error.response.status === 401) {
                            // Unauthorized error
                            toast.error("Unauthorized: You don't have permission to share this notebook.");
                        } else if (error.response.status === 500) {
                            // Internal Server Error
                            toast.error("Unable to share the notebook.");
                        } else {
                            toast.error("Error: " + error.response.data.message);
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        toast.error("Network Error: Unable to connect to the server.");
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        toast.error("Error: " + error.message);
                    }

                    // Handle other error cases as needed
                });
        } catch (error) {
            console.log("Couldn't send the share request!", error);
            setError(error);
            toast.error("Unable to send the share request.");
        }
    }

    return (
        <>
            <Toaster />
            <section className="md:mt-[-50px] bg-black dark:bg-gray-900">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Share Notebook
                            </h1>
                            <div className="space-y-4 md:space-y-6">
                                <div>

                                    <label htmlFor="email" className="block my-2 text-sm font-medium text-gray-900 dark:text-white">Hunter's Email</label>
                                    <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" onChange={(e) => handleChange(e.target.value)}
                                        value={email} required />
                                </div>

                                {error && <div className="mt-4 text-red-800 px-5 py-2 bg-red-200 rounded-md">{error}</div>}

                                <button disabled={sharing} type="submit" onClick={handleSubmit} className={`mt-5 w-full  bg-[#00df9a] ${sharing ? 'text-white' : 'text-black'}hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-[#00df9a] disabled:bg-slate-300`}>{sharing ? 'Sharing...' : 'Share'}</button>



                                {/* <p className="mt-3 text-sm font-light text-gray-500 dark:text-gray-400">
                                        Don't have an account? <a href="#" className="font-medium text-primary-600 hover:underline dark:text-primary-500"><Link to='/signup'>
                                            Create account here
                                        </Link></a>
                                    </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ShareNotebook;
