import React, { useState, useEffect } from 'react';
import Dashboard from './pages/Dashboard'
import About from './pages/About';
import NotebookPage from './pages/NotebookPage';
import ProfilePage from './pages/ProfilePage';
import CreateNotebookPage from './pages/CreateNotebookPage';
import SharedNotebooks from './components/Notebooks/SharedNotebooks';
import ShareNotebookPage from './pages/ShareNotebook';


import { Route, Routes, Navigate, useLocation } from 'react-router-dom';


import Login from './components/Authentication/Login';
import Signup from './components/Authentication/Signup';
import Home from './components/Home/Home';
import ReactGA from 'react-ga';


function initialiseAnalytics() {
  const TRACKING_ID = process.env.REACT_APP_GA_ID || 'G-86030T55TM';
  ReactGA.initialize(TRACKING_ID);
}

function usePageTracking() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initialiseAnalytics();
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
}

function App() {

  usePageTracking();
  const token = localStorage.getItem('token');
  
  return (
    <>      
      <Routes>
        <Route path='/' element={<Home />} />
        {token && <Route path="/dashboard" element={<Dashboard />} />}
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {token && <Route path="/profile" element={<ProfilePage />} />}

        {/* Notebooks */}
        {token && <Route path="/notebooks" element={<NotebookPage />} />}
        {token && <Route path="/notebooks/create" element={<CreateNotebookPage />} />}
        {token && <Route path="/notebooks/shared" element={<SharedNotebooks />} />}
        {token && <Route path="/notebooks/share-notebook/:id" element={<ShareNotebookPage />} />}


        {/* Auth */}
        <Route path="/profile" element={<Navigate replace to="/login" />} />
        <Route path="/dashboard" element={<Navigate replace to="/login" />} />
        <Route path="/notebooks" element={<Navigate replace to="/login" />} />
        <Route path="/notebooks/create" element={<Navigate replace to="/login" />} />
        <Route path="/notebooks/shared" element={<Navigate replace to="/login" />} />
        <Route path="/notebooks/share-notebook/:id" element={<Navigate replace to="/login" />} />

        {/* Saftey */}
        <Route path="*" element={<Navigate to="/dashboard" />}/>
      </Routes>
    </>
  );

}

export default App;
