import React from 'react'
import Navbar from '../components/Navbar'
import ShareNotebook from '../components/Notebooks/ShareNotebook'


const NotebookPage = () => {
    return (
        <>
            <Navbar />
            <ShareNotebook/>
        </>
    )
}

export default NotebookPage