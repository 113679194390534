import { useEffect, useState } from 'react';
import axios from 'axios';

const FetchTips = ({ onDataFetched }) => {

  const [loading, setLoading] = useState(true);
  const [tipsData, setTipsData] = useState([]);
  const [error, setError] = useState('');
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Hello hacker
        // API is open for everyone <3
        const axiosInstance = axios.create({
          baseURL: 'https://api.bountybible.com',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
          },
        });

        // Now you can use axiosInstance for your API calls
        const response = await axiosInstance.get('/api/tips');
        setTipsData(response.data);
        onDataFetched(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (tipsData.length === 0 && !error) {
      fetchData();
    }
  }, [onDataFetched, tipsData, token, error]);


  return null; // This component doesn't render anything
};

export default FetchTips;
