import React from "react";
const Hero = () => {
    return (
        <div className="text-white">
            <div className="max-w-[800px] mt-[70px] w-full mx-auto text-center flex flex-col justify-center px-3">
                <p className="text-[#00df9a] font-bold p-2">Abandon your twitter bookmark grave</p>
                <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">Bug Bounty Tips from Your Favorite Hackers</h1>
                <p className="py-6 md:text-2xl font-medium opacity-75 px-3">Explore valuable bug bounty tips, favorite the ones you love, and organize them into notebooks for efficient learning.</p>
                <a href='/signup' className="ease-linear animate-pulse bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black">Get started</a>

            </div>

        </div>
    )
}

export default Hero;